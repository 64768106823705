import styled, { css } from 'styled-components'

export const LayoutTypeStyleFormStyled = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    row-gap: ${theme.spacing(2)};

    padding: ${theme.spacing(4)};
    background: ${theme.colors.grey200};
  `
)
