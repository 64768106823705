import { useTranslation } from 'react-i18next'
import { InteriorStyleType } from '@api/endpoints/buildings/layoutTypes'
import { Box } from '@mui/material'
import { Text } from '@controls/Text'
import { LayoutTypeStyleForm } from './LayoutTypeStyleForm'
import { LayoutTypeStylesStyled } from './LayoutTypeStyles.styles'

export const LayoutTypeStyles = (): React.ReactElement => {
  const { t } = useTranslation()

  return (
    <LayoutTypeStylesStyled>
      <Text size="size-24" weight="regular" component="div" mb={2}>
        {t('pages.admin.layout_type.styles.title')}
      </Text>
      <Box display="flex" flexDirection="column" rowGap={4}>
        {InteriorStyleType.options.map((styleType) => (
          <LayoutTypeStyleForm key={styleType} styleType={styleType} />
        ))}
      </Box>
    </LayoutTypeStylesStyled>
  )
}
