import styled, { css } from 'styled-components'

export const LayoutTypeStylesStyled = styled('div')(
  ({ theme }) => css`
    padding: ${theme.spacing(4, 0)};

    form {
      display: flex;
      flex-direction: column;
      row-gap: ${theme.spacing(2)};
    }
  `
)
