import * as React from 'react'
import { DeepPartial } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as z from 'zod'
import { HtmlMouseButtonEventHandler } from '@digital-magic/react-common'
import { hasValue, reorderArray, sameArrayValues } from '@digital-magic/ts-common-utils'
import { getTranslatedField } from '@utils/language-utils'
import { Language } from '@api/endpoints/types'
import { isDeletableEntity } from '@api/endpoints/utils'
import {
  CreateLayoutTypeStyleRequest,
  InteriorStyleType,
  LayoutTypeStyleId,
  useCreateLayoutTypeStyle,
  useCreateLayoutTypeStyleImage,
  useDeleteLayoutTypeStyle,
  useDeleteLayoutTypeStyleImage,
  useGetLayoutTypeStyleImages,
  useSetPrimaryLayoutTypeStyleImage,
  useUpdateLayoutTypeStyle,
  useUpdateLayoutTypeStyleImagesOrder
} from '@api/endpoints/buildings/layoutTypes'
import {
  TranslatedFileList,
  convertTranslatedFileListToSingleFile,
  getSmallThumbnailId,
  undefinedTranslatedFileList,
  useFileUpload,
  useFilesDownload,
  useTranslatedFileUpload
} from '@api/endpoints/files'
import { useAdminDefaultErrorHandler } from '@hooks/useAdminDefaultErrorHandler'
import { Check, Delete } from '@mui/icons-material'
import { Box } from '@mui/material'
import { DropResult } from '@hello-pangea/dnd'
import { Button } from '@controls/Button'
import { ButtonWithConfirmation } from '@controls/Button/ButtonWithConfirmation'
import { DraggableImageList } from '@controls/DraggableImageList'
import { Form, FormFileInput, FormTextField, useFormTyped } from '@controls/Form'
import { Text } from '@controls/Text'
import { DownloadTranslatedFiles, UploadTranslatedFiles } from '@controls/TranslatedFile'
import { useLayoutTypeFormContext } from '../../LayoutTypeFormContext'
import { LayoutTypeStyleFormStyled } from './LayoutTypeStyleForm.styles'

type LayoutTypeStyleFormProps = {
  styleType: InteriorStyleType
}

const LayoutTypeStyleFormValues = CreateLayoutTypeStyleRequest.extend({
  document: TranslatedFileList.partial()
}).refine(
  (obj) =>
    sameArrayValues<boolean>([hasValue(obj.document?.eng), hasValue(obj.document?.est), hasValue(obj.document?.rus)]),
  {
    path: ['document', 'eng'],
    // TODO: Add translation
    message: 'Document file must be specified for either all languages or none!'
  }
)
type LayoutTypeStyleFormValues = z.infer<typeof LayoutTypeStyleFormValues>

const UploadImagesForm = z.object({
  images: z.instanceof(FileList).optional()
})

type UploadImagesForm = z.infer<typeof UploadImagesForm>

export const LayoutTypeStyleForm: React.FC<LayoutTypeStyleFormProps> = ({ styleType }) => {
  const { t } = useTranslation()
  const { layoutType } = useLayoutTypeFormContext()
  const defaultErrorHandler = useAdminDefaultErrorHandler()

  const layoutTypeId = layoutType.id
  const currentLayoutTypeStyle = layoutType.styles.find((s) => s.styleType === styleType)

  const getLayoutTypeStyleImages = useGetLayoutTypeStyleImages(layoutTypeId, currentLayoutTypeStyle?.id ?? '', {
    onError: defaultErrorHandler,
    enabled: hasValue(currentLayoutTypeStyle)
  })

  const uploadFile = useFileUpload({ onError: defaultErrorHandler })

  const createLayoutTypeStyle = useCreateLayoutTypeStyle({ onError: defaultErrorHandler })
  const updateLayoutTypeStyle = useUpdateLayoutTypeStyle({ onError: defaultErrorHandler })
  const deleteLayoutTypeStyle = useDeleteLayoutTypeStyle({ onError: defaultErrorHandler })

  const createLayoutTypeStyleImage = useCreateLayoutTypeStyleImage({ onError: defaultErrorHandler })
  const deleteLayoutTypeStyleImage = useDeleteLayoutTypeStyleImage({ onError: defaultErrorHandler })
  const setPrimaryLayoutTypeStyleImage = useSetPrimaryLayoutTypeStyleImage({ onError: defaultErrorHandler })
  const updateLayoutTypeImagesOrder = useUpdateLayoutTypeStyleImagesOrder({ onError: defaultErrorHandler })

  const isLoading =
    uploadFile.isLoading ||
    getLayoutTypeStyleImages.isFetching ||
    createLayoutTypeStyle.isLoading ||
    updateLayoutTypeStyle.isLoading ||
    deleteLayoutTypeStyle.isLoading ||
    createLayoutTypeStyleImage.isLoading ||
    deleteLayoutTypeStyleImage.isLoading ||
    setPrimaryLayoutTypeStyleImage.isLoading ||
    updateLayoutTypeImagesOrder.isLoading

  const images = React.useMemo(
    () => getLayoutTypeStyleImages.data?.concat()?.sort((a, b) => a.orderNumber - b.orderNumber) ?? [],
    [getLayoutTypeStyleImages.data]
  )

  const downloadImages = useFilesDownload(images.map(getSmallThumbnailId), { onError: defaultErrorHandler })

  const onDelete = (confirmResult: boolean): void => {
    const layoutTypeStyleId = currentLayoutTypeStyle?.id
    if (hasValue(layoutTypeStyleId) && confirmResult) {
      deleteLayoutTypeStyle.mutate({ layoutTypeId, layoutTypeStyleId })
    }
  }

  const onDeleteImage =
    (index: number) =>
    (confirmResult: boolean): void => {
      const fileId = images[index].id
      const layoutTypeStyleId = currentLayoutTypeStyle?.id

      if (hasValue(layoutTypeStyleId) && hasValue(fileId) && confirmResult) {
        deleteLayoutTypeStyleImage.mutate({ layoutTypeId, layoutTypeStyleId, fileId })
      }
    }

  const onDragEnd = (result: DropResult): void => {
    if (
      hasValue(currentLayoutTypeStyle) &&
      hasValue(result.destination) &&
      hasValue(result.source.index) &&
      result.destination.index !== result.source.index
    ) {
      const reordered = reorderArray(images, result.source.index, result.destination.index).map((v, i) => ({
        ...v,
        orderNumber: i
      }))
      updateLayoutTypeImagesOrder.mutate({
        layoutTypeId: layoutTypeId,
        layoutTypeStyleId: currentLayoutTypeStyle.id,
        items: reordered.map((v) => ({
          item: v.id,
          index: v.orderNumber
        }))
      })
    }
  }

  const onSetPrimaryLayoutTypeStyleImage: (index: number) => HtmlMouseButtonEventHandler = (index) => (e) => {
    e.preventDefault()
    const layoutTypeStyleId = currentLayoutTypeStyle?.id
    const fileId = images?.[index].id
    if (hasValue(layoutTypeStyleId) && hasValue(fileId)) {
      setPrimaryLayoutTypeStyleImage.mutate({ layoutTypeId, layoutTypeStyleId, fileId })
    }
  }

  const uploadImages = async (images: FileList, layoutTypeStyleId: LayoutTypeStyleId): Promise<void> => {
    // eslint-disable-next-line functional/no-loop-statements
    for (const image of images) {
      const uploadResp = await uploadFile.mutateAsync(image)
      await createLayoutTypeStyleImage.mutateAsync({ layoutTypeId, layoutTypeStyleId, fileId: uploadResp.id })
    }
  }

  const uploadTranslatedDocument = useTranslatedFileUpload(uploadFile)

  const onSubmit = async ({ document, ...values }: LayoutTypeStyleFormValues): Promise<void> => {
    const doc = convertTranslatedFileListToSingleFile(document)
    const documentId = hasValue(doc) ? await uploadTranslatedDocument(doc) : form.getValues('documentId')

    if (!hasValue(currentLayoutTypeStyle)) {
      createLayoutTypeStyle.mutate({ ...values, documentId, layoutTypeId })
    } else {
      updateLayoutTypeStyle.mutate({
        ...values,
        layoutTypeStyleId: currentLayoutTypeStyle.id,
        documentId,
        layoutTypeId
      })
    }
  }

  const onSubmitUpload = async ({ images }: UploadImagesForm): Promise<void> => {
    if (hasValue(images) && hasValue(currentLayoutTypeStyle)) {
      await uploadImages(images, currentLayoutTypeStyle.id)
    }
  }

  const defaultValues = React.useMemo((): DeepPartial<LayoutTypeStyleFormValues> => {
    const additionalPrice = hasValue(currentLayoutTypeStyle?.additionalPrice)
      ? Number(currentLayoutTypeStyle?.additionalPrice)
      : undefined

    return {
      styleType,
      additionalPrice,
      document: undefinedTranslatedFileList(),
      documentId: currentLayoutTypeStyle?.documentId
      //layoutTypeId
    }
  }, [currentLayoutTypeStyle, styleType])

  const form = useFormTyped<LayoutTypeStyleFormValues>({
    resolver: LayoutTypeStyleFormValues,
    onSubmit
  })

  const uploadForm = useFormTyped<UploadImagesForm>({
    resolver: UploadImagesForm,
    onSubmit: onSubmitUpload
  })

  const imageUrls: ReadonlyArray<string> = React.useMemo(
    () => downloadImages.data?.map((f) => URL.createObjectURL(f)) ?? [],
    [downloadImages.data]
  )

  const document = form.getValues('document')
  const documentId = form.getValues('documentId')

  const clearDocumentId = React.useCallback(() => {
    form.reset({ ...form.getValues(), documentId: undefined })
  }, [form])

  const clearUploadField = (lang: Language): void => {
    form.reset({
      ...form.getValues(),
      document: {
        ...document,
        [getTranslatedField(lang)]: undefined
      }
    })
  }

  React.useEffect(() => {
    form.reset(defaultValues)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues])

  // TODO: Decide if we want limit editing for layout type style (how it affects the Booking)
  //const isFormEditDisabled = useMemo(() => !isEditableEntity(layoutType.allowedActions), [layoutType])
  const isFormEditDisabled = false
  const isFormDeleteDisabled = React.useMemo(() => !isDeletableEntity(layoutType.allowedActions), [layoutType])

  return (
    <LayoutTypeStyleFormStyled>
      <Form f={form}>
        <Box display="flex" columnGap={2} mb={2}>
          <Text weight="regular" size="size-18" marginRight="auto">
            {styleType}
          </Text>
          {hasValue(currentLayoutTypeStyle) && (
            <ButtonWithConfirmation
              color="error"
              type="button"
              disabled={isLoading || isFormDeleteDisabled}
              onConfirmResult={onDelete}
              confirmTitle={t('global.consent.delete.title')}
              confirmMessage={t('global.consent.delete.message')}
            >
              <Delete />
            </ButtonWithConfirmation>
          )}
        </Box>
        <FormTextField
          disabled={isLoading || isFormEditDisabled}
          label={t('pages.admin.layout_type.styles.form.field.additional_price')}
          type="number"
          name={form.names.additionalPrice}
        />
        {hasValue(documentId) ? (
          <DownloadTranslatedFiles
            title={t('pages.admin.layout_type.styles.form.field.document') + ':'}
            translatedFileId={documentId}
            deleteDisabled={isLoading || isFormEditDisabled}
            onDelete={clearDocumentId}
          />
        ) : (
          <UploadTranslatedFiles
            title={t('pages.admin.layout_type.styles.form.field.document') + ':'}
            nameEng={form.names.document.eng}
            nameEst={form.names.document.est}
            nameRus={form.names.document.rus}
            disabled={isLoading || isFormEditDisabled}
            accept="application/pdf"
            onDelete={clearUploadField}
          />
        )}
        <div>
          <Button type="submit" disabled={isLoading || isFormEditDisabled}>
            {hasValue(currentLayoutTypeStyle)
              ? t('pages.admin.layout_type.styles.buttons.update')
              : t('pages.admin.layout_type.styles.buttons.create')}
          </Button>
        </div>
      </Form>
      {hasValue(currentLayoutTypeStyle) && (
        <>
          <DraggableImageList
            imageUrls={imageUrls}
            disabled={isLoading || isFormEditDisabled}
            onDragEnd={onDragEnd}
            onDeleteImage={onDeleteImage}
            additionalControls={(i) =>
              images?.[i].primaryImage === true ? (
                <Button disabled color="grey600">
                  <Check />
                </Button>
              ) : (
                <Button
                  disabled={isLoading}
                  onClick={onSetPrimaryLayoutTypeStyleImage(i)}
                  title="Set primary image for layout type style"
                >
                  <Check />
                </Button>
              )
            }
          />
          <Box mt={2} mb={2} borderTop={1} color="#B5B5B5" />
          <Form f={uploadForm}>
            <Box display="flex" alignItems="center" columnGap={2}>
              <span>{t('pages.admin.layout_type.styles.images.upload.title')}:</span>
              <FormFileInput
                name={uploadForm.names.images}
                disabled={isLoading || isFormEditDisabled}
                accept="image/*"
                multiple
              />
            </Box>
            <div>
              <Button type="submit" disabled={isLoading || isFormEditDisabled}>
                {t('pages.admin.layout_type.styles.buttons.upload')}
              </Button>
            </div>
          </Form>
        </>
      )}
    </LayoutTypeStyleFormStyled>
  )
}
